.Card th, td {
  padding: 10px;
  text-align: left;
}

.Card tr:nth-child(even) {background-color: var(--gray-color);}

.errorMessage {
  font-weight: bold;
  color: var(--primary-color);
  font-family: var(--font);
}

.currentUser {
  font-weight: bold;
  background-color: var(--primary-disabled-color);
  border-bottom: 1px solid var(--light-color);
  border-top: 1px solid var(--light-color);
}

.Card table {
  width: calc(100% - 30px);
  border-radius: 10px;
  border: 1px solid var(--light-color);
  margin-left:auto; 
  margin-right:auto;
  border-collapse: collapse;
}

.Card th {
  background-color: var(--gray-color);
  color: var(--light-color);
  padding-top: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid var(--light-color);
}

.Card {
  display: flex;
  justify-content: center;
  margin: 26px auto;
  border-radius: 6px;
  color: var(--light-color);
}

.AroundYourRankWrapper {
  width: 50%;
}

.TopTenWrapper {
  width: 50%;
}

.TopTenWrapper > p {
  text-align: center;
  font-family: var(--font);
  line-height: 100px;
  margin-left: auto;
  margin-right: auto;
}

.Title {
  text-align: center;
  color: var(--primary-color);
  padding: 0 50px 0 50px;
}

@media (max-width: 650px) {
  .Card {
    flex-direction: column;
    margin: auto;    
    padding: 0 0 20px 0;
  }

  .Title {
    padding: 0;
  }

  .TopTenWrapper > p {
    line-height: 0%;
    text-align: left;
  }

  .AroundYourRankWrapper {
    width: 100%;
  }
  
  .TopTenWrapper {
    width: 100%;
  }
}