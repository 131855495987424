/* Variable definitions */
:root {
  --primary-color: #3dcec1;
  --primary-disabled-color: #1f736c;
  --light-color: #e9e9ec;
  --gray-color: #36393e;
  --dark-gray-color: #181b20;
  --error-color: #f52f26;
  --success-color: #79b86f;
  --nav-height: 60px;
  --font: 'Nunito';

   /* Cards */
   --carnivore-color: #d36f63;
   --fish-color: #6ea6d9;
   --herbivore-color: #79b86f;
   --equipment-color: #3e3534;
   --spell-color: #A8A24D;
   --spell-boost-color: #666666;
   --paper-color: #fef5e9;
   --card-font-name: 'BebasNeueRegular';
   --card-font-description: 'OpenSans-Light';
   --card-font-card-stats: 'BebasNeueBold';
}

/* Fonts */
@font-face {
  font-family: 'Anton';
  src: local('Anton'), url(./assets/fonts/Anton-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 400;
  src: local('Nunito'), url(./assets/fonts/Nunito-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 700;
  src: local('Nunito Bold'), url(./assets/fonts/Nunito-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'BebasNeueBold';
  font-weight: 700;
  src: local('BebasNeueBold'), url(./assets/fonts/BEBASNEUE-BOLD.TTF) format('truetype');
}

@font-face {
  font-family: 'BebasNeueRegular';
  font-weight: 400;
  src: local('BebasNeueRegular'), url(./assets/fonts/BEBASNEUE-REGULAR.TTF) format('truetype');
}

@font-face {
  font-family: 'OpenSans-Light';
  font-weight: 200;
  src: local('OpenSans-Light'), url(./assets/fonts/OpenSans-Light.ttf) format('truetype');
}

/* Global styles */
body {
  margin: 0;
  font-family: var(--font), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(49,53,56);
  background: linear-gradient(180deg, rgba(49,53,56,1) 0%, rgba(31,35,38,1) 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
